import { FileCategory, SearchFilesResult, Source } from '@outmind/types';
import clsn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { useFeatureFlags, useLinkHandler, useResize, useRuntimeEnv } from '../../../hooks';
import { Grid, Paper } from '../../../material';
import { Actions, useDispatch, useSelector } from '../../../store';
import { getParentPath } from '../../../util';
import * as logoHelper from '../../../util';
import { ContextMenu } from '../../ContextMenu';
import { DocumentArborescence } from '../Arborescence';
import { ArchiveName } from '../ArchiveName';
import { DocumentContent } from '../DocumentContent';
import { DocumentDate } from '../DocumentDate';
import { DocumentHeader } from '../DocumentHeader';
import { DocumentParticipant } from '../DocumentParticipant';
import { DocumentSubject } from '../DocumentSubject';
import { DocumentThumbnail } from '../DocumentThumbnail';
import { useStyles } from '../styles';
import { DuplicateFiles } from './DuplicateFiles';

/**
 * Displays an ElasticFile document
 */
export const File: React.FC<FileProps> = ({
  bookmarkView,
  displayAsAttachment,
  fromSearchPerson,
  result,
  index,
  isSimilarFile,
}) => {
  const { document, similar } = result;
  const { accessLink, attachmentContext, clusterId, metas, mimeType, obfuscated, source, title } =
    document;

  const { inElectron } = useRuntimeEnv();

  const classes = useStyles({ bookmarkView, isSimilarFile });

  const dispatch = useDispatch();

  const previewInfos = useSelector((state) => state.previews.infos);

  const isPreviewOpened = useSelector((s) => s.previews.isOpened);

  const [isSelected, setIsSelected] = useState(false);

  const [mouseCoordinates, setMouseCoordinates] = useState<{
    mouseX: null | number;
    mouseY: null | number;
  }>({
    mouseX: null,
    mouseY: null,
  });

  const pathRef = useRef<HTMLDivElement | null>(null);
  const { width } = useResize(pathRef);

  const { withFilePreview } = useFeatureFlags();

  const goToFile = useLinkHandler(accessLink);

  const openPreview = (): void => {
    dispatch(Actions.openPreview());
    dispatch(Actions.selectFilePreview(result));
  };

  const filterByfolder = (): void => {
    dispatch(Actions.addFolderFilter(document));
  };

  const onclickHandler = (): (() => void) | undefined => {
    if (mouseCoordinates.mouseX && mouseCoordinates.mouseY) return;
    if (obfuscated) return;
    if (withFilePreview) return openPreview;
    return goToFile;
  };

  const handleRightClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.preventDefault();
    if (!mouseCoordinates.mouseX && !mouseCoordinates.mouseY) {
      setMouseCoordinates({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
      });
      setIsSelected(true);
    }
  };

  const handleMenuClose = (): void => {
    setIsSelected(false);
    setMouseCoordinates({
      mouseX: null,
      mouseY: null,
    });
  };

  const openInFileExplorer = (): void => {
    if (document.parents?.length && accessLink) {
      const parentPath = getParentPath(
        accessLink,
        document.parents[document.parents.length - 1],
        title,
      );
      if (parentPath) {
        window.electron!.openLocalFile(parentPath);
      }
    }
  };

  useEffect(() => {
    setIsSelected(previewInfos?.document.id === document.id);
  }, [previewInfos?.document.id, document.id]);

  const creatorName = document.by?.name;

  const attachmentSender = attachmentContext?.from_new?.name || attachmentContext?.from;
  const attachmentReceiver = attachmentContext?.to_new
    ? attachmentContext.to_new.map((p) => p.name).join(', ')
    : attachmentContext?.to;
  const attachmentSubject = attachmentContext?.subject;

  const isCreatedDate = document.date === document.createdDate;
  const isLastModifiedDate = document.date === document.lastModifiedDate;

  const titleHighlighted = result.highlights?.title || title;
  const contentHighlighted = result.highlights?.content;

  const creatorNameHighlighted = result.highlights?.by || creatorName;

  const parentHighlighted = result.highlights?.parents || document.parents;

  const attachmentSenderHighlighted = result.highlights?.by || attachmentSender;

  const fileCreator = (
    <>
      {creatorNameHighlighted && !attachmentSenderHighlighted ? (
        <DocumentParticipant name={creatorNameHighlighted} />
      ) : null}
      {attachmentSenderHighlighted ? (
        <DocumentParticipant name={attachmentSenderHighlighted} />
      ) : null}
      {attachmentReceiver ? <DocumentParticipant isReceiver name={attachmentReceiver} /> : null}
      {attachmentSubject ? <DocumentSubject subject={attachmentSubject} /> : null}
    </>
  );

  const archiveName =
    metas?.archive && !isPreviewOpened ? (
      <div className={classes.displayFlex}>
        <span style={{ marginLeft: '8px' }}>
          <ArchiveName name={metas.archive.name} type={metas.archive.type} />
        </span>
      </div>
    ) : null;

  const duplicateFileData = isSimilarFile ? (
    <div className={classes.resultDuplicateElementMetas}>
      {fileCreator}
      {creatorName || attachmentSender || attachmentReceiver || attachmentSubject ? (
        <span className={classes.medianContainer}>·</span>
      ) : null}
      <div className={classes.documentDateContainer}>
        <DocumentDate
          date={document.date}
          isCreatedDate={isCreatedDate}
          isLastModifiedDate={isLastModifiedDate}
          isSentDate={!!attachmentContext}
        />
      </div>
      {archiveName}
    </div>
  ) : undefined;

  const dateData = (
    <div>
      {!displayAsAttachment ? (
        <DocumentDate
          date={document.date}
          isCreatedDate={isCreatedDate}
          isLastModifiedDate={isLastModifiedDate}
          isSentDate={!!attachmentContext}
        />
      ) : null}
    </div>
  );

  const fileData = (
    <>
      {!displayAsAttachment ? (
        <div className={classes.resultElementMetas}>
          {fileCreator}
          <div className={classes.displayFlex}>
            {parentHighlighted ? (
              <DocumentArborescence
                containerWidth={width ?? pathRef?.current?.offsetWidth}
                parents={parentHighlighted!}
              />
            ) : null}
            {archiveName}
          </div>
          {creatorName || attachmentSender || attachmentReceiver || attachmentSubject ? (
            <span className={classes.medianContainer}>·</span>
          ) : null}
          <div className={classes.dateContainer}>{dateData}</div>
        </div>
      ) : null}
      <DocumentContent content={contentHighlighted} />
    </>
  );
  const children = (
    <Grid>
      <Grid
        className={clsn({
          [classes.fileDocumentContainer]: true,
          [classes.fileDocumentContainerClicked]: isSelected,
        })}
        container
        id={`file-result-container-${index}`}
        onClick={onclickHandler()}
        onContextMenu={handleRightClick}
      >
        <div className={classes.thumbnailsContainer}>
          {(document.thumbnail || document.thumbnailUrl) && !bookmarkView ? (
            <DocumentThumbnail
              isSimilarFile={isSimilarFile}
              thumbnail={document.thumbnailUrl ?? document.thumbnail}
            />
          ) : null}
          {!document.thumbnail && !document.thumbnailUrl && !bookmarkView ? (
            <div className={classes.emptyThumbnail}>
              {mimeType ? logoHelper.getFileLogo(mimeType, classes.thumbnailLogo) : null}
            </div>
          ) : null}
          <div className={classes.fileDataContainer}>
            <div className={classes.documentHeaderContainer}>
              <DocumentHeader
                accessLink={accessLink}
                bookmarked={document.bookmarked}
                displayAsAttachment={displayAsAttachment}
                documentId={document.id}
                index={index}
                isLocalFile={source === Source.LocalDisk || source === Source.NetworkDisk}
                labels={document.labels}
                mimeType={mimeType}
                obfuscated={obfuscated}
                shouldDisplayLabels={!fromSearchPerson}
                source={source}
                title={titleHighlighted}
                withBookmark
              />
            </div>
            {!bookmarkView ? (
              <Grid ref={pathRef}>{isSimilarFile ? duplicateFileData : fileData}</Grid>
            ) : null}
          </div>
        </div>
      </Grid>
      {similar?.documents.length && !bookmarkView ? (
        <Grid className={classes.duplicateFilesContainer}>
          <div className={classes.duplicateFileContainer}>
            <DuplicateFiles
              clusterId={clusterId}
              documentId={document.id}
              documentName={title}
              similar={similar}
            />
          </div>
        </Grid>
      ) : null}
    </Grid>
  );

  return (
    <Paper elevation={0} square>
      {children}
      {mouseCoordinates.mouseX && mouseCoordinates.mouseY ? (
        <ContextMenu
          bookmarked={document.bookmarked}
          documentId={document.id}
          mouseX={mouseCoordinates.mouseX}
          mouseY={mouseCoordinates.mouseY}
          onClose={handleMenuClose}
          onFolderSearch={document.category === FileCategory.Folder ? filterByfolder : undefined}
          onOpen={goToFile}
          onOpenInFileExplorer={
            source === Source.LocalDisk && inElectron ? openInFileExplorer : undefined
          }
          onPreview={document.category !== FileCategory.Folder ? openPreview : undefined}
        />
      ) : null}
    </Paper>
  );
};

interface FileProps {
  bookmarkView?: boolean;
  displayAsAttachment?: boolean;
  fromSearchPerson?: boolean;
  index?: string;
  isSimilarFile?: boolean;
  result: SearchFilesResult;
}
