import { SearchPagesResult } from '@outmind/types';
import { PersonCircle } from '@styled-icons/fluentui-system-filled/PersonCircle';
import React, { useEffect, useState } from 'react';
import { AspectRatio } from 'react-aspect-ratio';

import NoImageSrc from '../../../assets/no-image.png';
import { useFeatureFlags, useLinkHandler, useTimeAgo } from '../../../hooks';
import { Card, CardContent, Grid, Paper, Typography } from '../../../material';
import { Actions, useDispatch, useSelector } from '../../../store';
import * as logoHelper from '../../../util';
import { ConnectorLogos, SanitizedHtmlTypography } from '../..';
import { ContextMenu } from '../../ContextMenu';
import { DocumentLabels } from '../../DocumentLabels';
import { PageIndex } from './PageIndex';
import { useStyles } from './styles';

/**
 * Displays a Page component
 */
export const Page: React.FC<PageProps> = ({ index, result }) => {
  const {
    document: {
      accessLink,
      by,
      date,
      id,
      labels,
      mimeType,
      obfuscated,
      parentFile,
      parentPresentation,
      pageIndex,
      thumbnail,
      thumbnailUrl,
      slideIndex,
      source,
      title,
    },
  } = result;

  const { withDocumentLabels } = useFeatureFlags();

  const classes = useStyles();

  const dispatch = useDispatch();

  const makeTimeAgo = useTimeAgo();

  const { infos: previewInfos, isOpened } = useSelector((state) => state.previews);

  const [isSelected, setIsSelected] = useState(false);

  const [mouseCoordinates, setMouseCoordinates] = useState<{
    mouseX: null | number;
    mouseY: null | number;
  }>({
    mouseX: null,
    mouseY: null,
  });

  const pageNumber = pageIndex ?? slideIndex;
  const parentFileName = parentFile?.name ?? parentPresentation?.name;
  const totalPagesNumber = parentFile?.totalPagesNumber ?? parentPresentation?.totalSlidesNumber;

  const { name: authorName } = { ...by };

  const titleHighlighted = result.highlights?.title || title;
  const connectorLogo = source ? ConnectorLogos[source] : null;

  const ownerInfo = authorName && (
    <div className={classes.ownerContainer}>
      <PersonCircle className={classes.ownerIcon} size="16" />
      <Typography className={classes.ownerText}>{authorName}</Typography>
    </div>
  );

  const displayDate = (
    <div className={classes.dateContainer}>
      <Typography className={classes.dateText}>{makeTimeAgo(date)}</Typography>
    </div>
  );

  useEffect(() => {
    setIsSelected(previewInfos?.document.id === id);
  }, [previewInfos?.document.id, id]);

  const openPreview = (): void => {
    dispatch(Actions.openPreview());
    dispatch(Actions.selectPagePreview(result));
  };

  const handleRightClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.preventDefault();
    if (!mouseCoordinates.mouseX && !mouseCoordinates.mouseY) {
      setMouseCoordinates({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
      });
      setIsSelected(true);
    }
  };

  const handleMenuClose = (): void => {
    setIsSelected(false);
    setMouseCoordinates({
      mouseX: null,
      mouseY: null,
    });
  };

  const onclickHandler = (): (() => void) | undefined => {
    if (mouseCoordinates.mouseX && mouseCoordinates.mouseY) return;
    return handleClick;
  };

  const goToPage = useLinkHandler(accessLink);

  const handleClick = (): void => {
    if (obfuscated) return;
    openPreview();
  };

  return (
    <Grid key={id} item lg={isOpened ? 6 : 4} md={isOpened ? 12 : 6} sm={12}>
      <div className={classes.pageCardContainer}>
        <Card className={classes.cardRoot} elevation={0}>
          <Paper
            className={classes.smartLink}
            elevation={0}
            id={`page-result-container-${index}`}
            onClick={onclickHandler()}
            onContextMenu={handleRightClick}
          >
            <div className={classes.thumbnailContainer}>
              <div className={classes.imageContainer}>
                <div className={classes.imageResponsiveContainer}>
                  <AspectRatio
                    ratio="16/3"
                    style={{ maxWidth: '100%', overflow: 'hidden', position: 'absolute', top: 0 }}
                  >
                    <img
                      alt="thumbnail"
                      className={classes.thumbnail}
                      src={thumbnailUrl ?? thumbnail ?? NoImageSrc}
                    />
                  </AspectRatio>
                </div>
              </div>
              {pageNumber && totalPagesNumber ? (
                <div className={classes.pageIndex}>
                  <PageIndex pageIndex={pageNumber} totalPagesNumber={totalPagesNumber} />
                </div>
              ) : null}
            </div>
            <CardContent
              classes={{ root: classes.cardContentRoot }}
              className={isSelected ? classes.hovered : undefined}
            >
              <div className={classes.contentContainer}>
                <div className={classes.titleContainer}>
                  {mimeType
                    ? logoHelper.getFileLogo(mimeType, classes.resultElementFileType)
                    : null}
                  {connectorLogo ? (
                    <img alt="" className={classes.connectorLogo} src={connectorLogo} />
                  ) : null}
                  {parentFileName ? (
                    <SanitizedHtmlTypography
                      className={classes.title}
                      href={accessLink}
                      html={titleHighlighted}
                      id={`page-title-${index ?? id}`}
                      variant="h6"
                    />
                  ) : null}
                </div>
                <div className={classes.pageInfoContainer}>
                  {ownerInfo}
                  {displayDate}
                </div>
              </div>
              {labels && withDocumentLabels ? (
                <div className={classes.labelsContainer}>
                  <DocumentLabels documentId={id} labels={labels} />
                </div>
              ) : null}
            </CardContent>
            {mouseCoordinates.mouseX && mouseCoordinates.mouseY ? (
              <ContextMenu
                documentId={id}
                mouseX={mouseCoordinates.mouseX}
                mouseY={mouseCoordinates.mouseY}
                onClose={handleMenuClose}
                onOpen={goToPage}
                onPreview={openPreview}
              />
            ) : null}
          </Paper>
        </Card>
      </div>
    </Grid>
  );
};

interface PageProps {
  index?: string;
  result: SearchPagesResult;
}
